import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QNAP as FTP Server",
  "path": "/Motion_Detection/Router_as_a_FTP_Server/QNAP_as_FTP_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use the FTP service of your router or network drive to back up your alarm video and images.",
  "image": "MD_SearchThumb_RouterAsFTPServer_QNAP.png",
  "social": "/images/Search/MD_SearchThumb_RouterAsFTPServer_QNAP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Router-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='QNAP as FTP Server' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the FTP service of your router or network drive to back up your alarm video and images.' image='/images/Search/MD_SearchThumb_RouterAsFTPServer_QNAP.png' twitter='/images/Search/MD_SearchThumb_RouterAsFTPServer_QNAP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Motion_Detection/Router_as_a_FTP_Server/QNAP_as_FTP_Server/' locationFR='/fr/Motion_Detection/Router_as_a_FTP_Server/QNAP_as_FTP_Server/' crumbLabel="QNAP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "router-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#router-as-ftp-server",
        "aria-label": "router as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Router as FTP Server`}</h1>
    <h2 {...{
      "id": "qnap-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qnap-as-ftp-server",
        "aria-label": "qnap as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QNAP as FTP Server`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/0a47e/QNAP-FTP_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACBUlEQVQoz42SyW4UMRCG+/0lrhEnDgghDrwCYlEOEA0hM0jRhCTKLMnMdHvvbm9llwt1N4EoQYiSZZVsf+X6/buqubprZNt7IiqlICIAWGt8hPnZV6OFae16uyMioXXBEiC1dji8ta7aM7na8atNLY3FEfa239XblPPp53fWNJvbw8lsTkTny4sEmQt5eX1DRJ8uzivnnZBKSwUQiQiJ1qJf7uRG9FoZqTRiLoilFCpERDHG4ENGdF1XvXj94ejV+2cvj4/ezp6/OdksV9ctnKz5FesE50LInDOOY0jGKpO6QlTNTn98PF58ma/Plvtv57et7jJnarUqmDEj/S2GLsa5SjkbKQoEKnlajdtbfnndCNWaDkuhlLrF91ab1nQ5DRJ+V6naEDc1k0JyLiOknPPAA9jeTjmV0s0XfF8zJrTStrd/YNGHmzsWfMBBEU5AZ0y9r1ttpjbN8iL5wR5n3Sj2HrbOm866APc+Z8TCheZMcqZCgJRy64NzIcaklAkxQcIYM0CuHr1EKWhd3NVGamctWBetC9YGa2PvgvPgXHQ+9jb0LlYjM1k4wL90/l88vnn4nhEOu0Pf9VMtHGPazxlL+SccQ2z2Tb1vWMNZw5oDU1I554IPRht8aNVTOKckmOANl0I2h8boNsZorYMI+PCtn8KTZgAIIXjvQwgJUkoJxnik+Sfjol69RI00EQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ea4b4438b8b173b534dcce75c0ca30b/e4706/QNAP-FTP_01.avif 230w", "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/d1af7/QNAP-FTP_01.avif 460w", "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/56a33/QNAP-FTP_01.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ea4b4438b8b173b534dcce75c0ca30b/a0b58/QNAP-FTP_01.webp 230w", "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/bc10c/QNAP-FTP_01.webp 460w", "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/e88ff/QNAP-FTP_01.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ea4b4438b8b173b534dcce75c0ca30b/81c8e/QNAP-FTP_01.png 230w", "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/08a84/QNAP-FTP_01.png 460w", "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/0a47e/QNAP-FTP_01.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ea4b4438b8b173b534dcce75c0ca30b/0a47e/QNAP-FTP_01.png",
              "alt": "QNAP as FTP Server",
              "title": "QNAP as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Log into your QNAP´s webUI, open the settings tab Network Services / FTP. Activate the FTP service and leave all parameters at their defaults.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/0a47e/QNAP-FTP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABy0lEQVQoz1XSXY+qMBAGYP7/H/HKuKs5q3wpiOtKW0otX2oXBFoMEI1m9Vx45Qm6evRJb6fvTGYk0zRlWTYMA2OcpinnPHuW54yxxWIRJ431kyRJpLf3t1arZU0mGDtRGAZBkGWZuOG82BSZ4GVZnc/nw+Gwf7LdbqVOp9Nut23bRgiNdH00HDoYO1cIIYxdIYqqrquyzvM8TdfpXZPc7XZ7vR6EECFkjceaotq27d3N5yQMgp+f4/F4KstSCLEpNjdCCOn9Cl1zTMPQFFWRFdsGhBDXdQkhnudlWS54wTnPn6Rp2rQtD2QIIQBgYk10TQfXSt/3KaV0Pl9GyyROkjjhXHDOm/BH8mKxFELs93vGGADgd1gIgQ2w41BKPUp93ycucbGLkeNRj12tViup4OJ0+nu5XIpCDPWhruqmYRij5hFCKKWr5fL71f/iOIlFUex2O8a+VVn9+PNhjS179jX9nAIAPM/jnBd3mydN2xCi28AAAF3TB/2BMpD7/YGqaNPPqeu6gv9u/XYYD3EcSwihx1Z1TVNlxRpbY9Ocfc1834/CqCzLqqrqut68apLRHYQwCIIojOI4vn3MGEM2CP3Q9zyMcPpqvV7/A27/WQaNX7mlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/e4706/QNAP-FTP_02.avif 230w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/d1af7/QNAP-FTP_02.avif 460w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/56a33/QNAP-FTP_02.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/a0b58/QNAP-FTP_02.webp 230w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/bc10c/QNAP-FTP_02.webp 460w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/e88ff/QNAP-FTP_02.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/81c8e/QNAP-FTP_02.png 230w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/08a84/QNAP-FTP_02.png 460w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/0a47e/QNAP-FTP_02.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/0a47e/QNAP-FTP_02.png",
              "alt": "QNAP as FTP Server",
              "title": "QNAP as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the FTP settings of camera and type in your QNAP´s IP address, an upload directory and your QNAP login - submit and test.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/58cde24c164fe7572a1106c8c24a92d1/0a47e/QNAP-FTP_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeElEQVQoz0WS3W7kIAyF5/1frep2NdrrNjEQhoQfZ8AkQJhUkN3tdwGW4PjYlm85l7ils1Nfr9f5A8Xt6Ylou+LaH2t9bdt+nufrPG9bjGVPpZSjlFprv8txHP1fPRotZ73OWpvsH7cwK7z/meQ8iWlWixATA865sIuNFF0n5+yfT+ccOiyl5Y0U95RuQT2Wt/cBOAzwkGoYxs/PLwYMDfrgtV60Niml57pa66wxlziEsG37X2epFjlJpeZpkkIIxriZTYwRf5w9OlwRjy7eYkwpN2f9/gFccmBKzQBsGEYAQIuByBhjmnP2ayvbGns5Uwj75Ww/7lw8OBNyejDGARgDptVCFIwx1tqccy/bonWllCYm2vfWs7K/70IqKSa9aC7EOMLEJRoXiZyzzvayvV/XFZ0rxzUwagMj9TBvv0YQMAIDzoAvi7baoEXvve6klFbE3oLN3Tn4PjBCTNoQRSIKPlCg/0tSStkbqdaac04ppT1dq3LtwjfQFTatuYy2DwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58cde24c164fe7572a1106c8c24a92d1/e4706/QNAP-FTP_03.avif 230w", "/en/static/58cde24c164fe7572a1106c8c24a92d1/d1af7/QNAP-FTP_03.avif 460w", "/en/static/58cde24c164fe7572a1106c8c24a92d1/56a33/QNAP-FTP_03.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/58cde24c164fe7572a1106c8c24a92d1/a0b58/QNAP-FTP_03.webp 230w", "/en/static/58cde24c164fe7572a1106c8c24a92d1/bc10c/QNAP-FTP_03.webp 460w", "/en/static/58cde24c164fe7572a1106c8c24a92d1/e88ff/QNAP-FTP_03.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58cde24c164fe7572a1106c8c24a92d1/81c8e/QNAP-FTP_03.png 230w", "/en/static/58cde24c164fe7572a1106c8c24a92d1/08a84/QNAP-FTP_03.png 460w", "/en/static/58cde24c164fe7572a1106c8c24a92d1/0a47e/QNAP-FTP_03.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/58cde24c164fe7572a1106c8c24a92d1/0a47e/QNAP-FTP_03.png",
              "alt": "QNAP as FTP Server",
              "title": "QNAP as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Activate your camera´s FTP upload and check the upload directory.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      